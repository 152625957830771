
.addImageBackground {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: grid;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.45);
    z-index: 1;
    backdrop-filter: blur(2px);
}

.addImageContainer {
    opacity: 0.7;
    max-width: 500px;
    background-color: white;
    padding: 15px;
    z-index: 2;
    border-radius: 7px;
}

.addImageContainer > p {
    margin: 0;
}

.addImageContainer .imageContainer {
    width: 40%;
    margin: auto;
}

.addImageContainer .imageContainer img {
    width: 100%;
    padding: 7px;
}

.addImageContainer .addImageForm {
    display: flex;
    flex-direction: column;
}

input:valid {
    display: none;
}
