.addNav {
    font-size: 50pt;
    text-decoration: none;
    align-self: center;
    background-color: transparent;
    border: 1px black solid;
    border-radius: 5pt;
    color: black;
}

.addImageForm {
    background-color: #f1f0f0;
    margin: 5px;
    padding: 10px;
    display: grid;
    grid-template-columns: auto auto 100px 50px;
}

.addImageForm input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
    height: 0;
    width: 0;
}

.addImageForm input {
}

.fileInputButton {
    box-shadow: 0 0 3px 1px black;
    margin: 3px;
}

.switchPicEditButton {
    margin-top: 20px;
    display: block;
    border: none;
    box-shadow: 0 0 4px 3px black;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background-color: lightblue;
}

.newProductLabel {
    font-weight: bold;
    margin-top: 18px;
    font-size: 12px;
    text-align: center;
}
