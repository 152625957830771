* {
    font-family: Helvetica, sans-serif;
}

main {
    min-height: calc(100vh - 110px);
}

footer {
    text-align: center;
    height: 37px;
    width: 100vw;
    border-top: 1px black solid;
    color: black;
}

.boxShadow {
    box-shadow: 0 0 2px 1px rgba(30, 39, 47, 0.67);
}

