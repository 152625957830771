.good {
    background-color: transparent;
}

.bad {
    background-color: rgba(247, 216, 216, 0.6);
}

.line {
    display: grid;
    grid-template-columns: 5vw 20vw auto 5vw 5vw;
    box-shadow: 0 0 6px black;
    margin: 5px;
    border-radius: 5px;
    align-items: center;
    height: auto;
}

.line > input[type="text"], .line > textarea {
    height: 100%;
    width: 95%;
    margin: 0 5px 0 5px;
    display: block;
    text-align: center;
    min-height: 10px;
    z-index: 1;
    font-size: 12px;
    user-select: none;
    border: none;
}

.line textarea {
}

.line button {
    max-height: 30px;
    opacity: 0.8;
    display: block;
    font-size: 14px;
    margin: 5px;
    color: black;
    font-weight: bold;
    background-color: transparent;
    border-radius: 5px;
    padding: 1px 5px 1px 5px;
    border: none;
    box-shadow: 0 0 2px 2px black;
}

.line button:nth-child(1) {
    background-color: #f87436;
}

.line button:nth-child(2) {
    background-color: #74cb74;
}

.imageContainer {
    display: flex;
    align-items: center;
}

.imagesBox {
    padding-top: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.imagesBox img {
    max-height: 60px;
    margin-left: 7px;
    max-width: 60px;
}

.deleteImage {
    box-shadow: 0 0 4px 2px red;
}

.imagesButtonBox {
    display: flex;
    flex-direction: column-reverse;
}

.imagesButtonBox button {
    font-size: 12px;
    margin: 3px;
}

.line .deleteButton {
    background-color: #f87436;
}

.line .submitButton {
    background-color: #74cb74;
}

@media screen and (min-width: 600px) {
    .line label {
        margin-left: calc(50vw - 250px);
    }

    .line > input[type="text"], .line > textarea {

    }

    .line > button {
        right: calc(50vw - 270px);
    }
}
