.lineGuest {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0 0 3px 2px rgba(30, 94, 118, 0.46);
    width: 98vw;
    border-radius: 5px;
}


.title {
    margin: 0 2px 5px 2px;
    width: 98%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.lineGuest .description {
    box-shadow: 0 0 4px 1px #e6e8f2;
    width: 70vw;
    border-radius: 10px;
    font-size: 12px;
    text-align: center;
    padding: 10px 5px 10px 5px;

}

/* IMAGES and Container */

.lineGuest .imageContainer {
    width: 20vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.lineGuest img {
    border-radius: 8px;
    max-width: 90%;
    max-height: 120px;
    box-shadow: 0 0 6px 3px lightgrey;
}

.lineGuest .imageContainer .picPresentation {
    width: 100%;
    text-align: center;
    margin-top: 5px;
}

.picPresentation img {
    max-width: 150px;
    max-height: 150px;
}

.lineGuest .imageContainer .allImages {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lineGuest .imageContainer .allImages div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-top: 1px black solid;
}

.lineGuest .imageContainer .allImages div img {
    max-width: 40px;
    max-height: 40px;
    filter: blur(0.6px) grayscale(0.6);
}


@media screen and (max-width: 500px) {
    .lineGuest .imageContainer {
        width: 100vw
    }

    .lineGuest .description {
        width: 100vw
    }
}
